@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=['roboto']:wght@100;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@layer base {
    button {
        @apply rounded-sm text-black py-2 px-5;
    }
    label {
        @apply inline-block py-3 text-sm font-medium float-left;
    }
    h1 {
        @apply text-4xl font-bold;
    }
    h2 {
        @apply text-3xl font-bold;
    }
    h3 {
        @apply text-2xl font-bold;
    }
    h4 {
        @apply text-2xl font-bold;
    }
    h5 {
        @apply text-xl font-bold;
    }
    h6 {
        @apply text-lg font-bold;
    }
}

@layer components {
    .btn:hover {
        @apply bg-[#136CC5];
    }

    .btn-primary {
        @apply bg-[#136CC5] rounded text-white font-medium;
    }
    .btn-secondary {
        @apply text-white font-medium;
    }
    .loginform label {
        @apply inline-block text-white font-[600];
    }
    .loginform div {
        @apply my-5;
    }
    .loginform input {
        @apply rounded block w-full py-4 bg-[#B6DAF436] px-2 text-white font-normal font-['roboto'] text-sm;
    }
    .loginform input::placeholder {
        @apply text-white;
    }
    .loginform button {
        @apply my-10 max-lg:w-auto;
    }
    .loginform button:hover {
        @apply bg-[#00AF9A];
    }

    .loginform2 label {
        @apply inline-block text-black font-[700];
    }
    .loginform2 input {
        @apply rounded block w-full py-3 bg-[#B6DAF436] px-2 text-black font-normal font-['roboto'] text-sm;
    }
    .loginform2 input::placeholder {
        @apply text-[#808080];
    }
    .loginform2 button {
        @apply bg-[#489BF6] rounded-md text-white font-[600] p-3 my-4 transition duration-300 ease-in-out hover:scale-[1.05];
    }
    .loginform2 button:hover {
        @apply bg-[#00AF9A];
    }
    /**Login with Code starts**/
    .loginwithcode label {
        @apply inline-block text-black font-[600];
    }
    .loginwithcode div {
        @apply my-5;
    }
    .loginwithcode input {
        @apply rounded block w-full py-4 bg-[#B6DAF436] px-2 text-black font-normal font-['roboto'] text-sm;
    }
    .loginwithcode input::placeholder {
        @apply text-black;
    }
    .loginwithcode button {
        @apply my-10 max-lg:w-auto;
    }
    .loginwithcode button:hover {
        @apply bg-[#00AF9A];
    }
    /**loginwithcode ends**/
    .wrapper-contactus {
        @apply flex justify-center items-center mb-14;
    }
    .accordition-contactus {
        @apply w-8/12 max-md:w-full;
    }
    .item-contactus {
        @apply mb-5 px-7 py-3 border border-0 border-b-2 border-b-[#F1F1F1] cursor-pointer;
    }
    .item-contactus h5 {
        @apply font-['roboto'] text-xl font-semibold;
    }
    .item-contactus div:last-child {
        @apply font-['roboto'] text-[#777777] my-2;
    }
    .tittle-contactus {
        @apply font-semibold flex justify-between items-center;
    }
    .content-contactus {
        @apply text-[#1C2347] font-['roboto'] font-medium  overflow-hidden transition-all max-h-0;
    }
    .content-contactus.show {
        @apply text-[#1C2347] font-['roboto']  overflow-hidden transition-all h-auto max-h-96;
    }
    .faq-sidebar {
        @apply p-2 py-20 max-md:py-2 max-md:flex;
    }
    .faq-sidebar div {
        @apply border border-0 border-b-[1px] text-base cursor-pointer  py-4 max-md:py-2  max-md:border-0 max-md:text-xs max-md:px-1 font-semibold font-['roboto'];
    }
    .faq-sidebar div:last-child {
        @apply border border-0;
    }
    .faq-sidebar div:hover {
        @apply text-[#136CC5];
    }
    .div-cards-vrauto {
        @apply m-5 max-md:m-3 px-10 bg-[#FFFFFF4F] text-white rounded-xl;
    }
    .div-cards-vrauto img {
        @apply pt-5;
    }
    .div-cards-vrauto h5 {
        @apply mt-5;
    }
    .div-cards-vrauto p {
        @apply py-5;
    }
    .lists {
        @apply py-5;
    }
    .lists h3 {
        @apply text-xl;
    }
    .lists p {
        @apply py-3 font-['roboto'] text-[#6B6B6B] font-medium;
    }
    .div-cards {
        @apply m-6 max-md:m-4 px-10 bg-[#FFFFFF3D] text-white rounded-xl;
    }
    .div-cards:hover {
        @apply bg-[#136CC5];
    }
    .div-cards img {
        @apply pt-5;
    }
    .div-cards h5 {
        @apply mt-10;
    }
    .div-cards p {
        @apply py-10;
    }

    .div-cards-predictive {
        @apply m-6 max-md:m-4 px-10 bg-[#136CC5]  text-white rounded-xl;
    }

    .div-cards-predictive img {
        @apply pt-5;
    }
    .div-cards-predictive h5 {
        @apply mt-10;
    }
    .div-cards-predictive p {
        @apply py-10;
    }
    .div-cards-vrretail {
        @apply m-5 px-10 bg-white text-[#1C2347] rounded-xl max-w-md shadow-2xl border-t-[6px] border-t-[#136CC5];
    }
    .div-cards-vrretail img {
        @apply pt-5;
    }
    .div-cards-vrretail h5 {
        @apply mt-5;
    }
    .div-cards-vrretail p {
        @apply py-5;
    }
    .retail-list {
        @apply py-3 font-['roboto'] font-medium;
    }
    .home-form {
        @apply block p-5 bg-[#1C2347] text-white  rounded-[0_20px_20px_0];
    }
    .home-form input {
        @apply block  py-2 px-1 max-md:px-0 rounded w-full text-[#1C2347];
    }
    .home-form input::placeholder {
        @apply p-2;
    }
    .home-form textarea {
        @apply my-5 py-20 px-1 max-md:px-0 block rounded w-full text-[#1C2347];
    }
    .home-form textarea::placeholder {
        @apply px-2;
    }
    .branch {
        @apply before:content-['---'] before:-translate-y-10 before:w-[2px] mt-10   before:bg-white;
    }
    .branch-list li {
        @apply before:content-["----"] before:mr-4 before:mt-4 before:tracking-tightest before:text-white;
    }

    .dropdown-menu {
        @apply cursor-pointer;
    }
    .dropdown-menu-items {
        @apply hidden;
    }
    .dropdown-menu:hover .dropdown-menu-items {
        @apply block absolute z-10 bg-white px-5 py-3 rounded font-['roboto'] text-darkblue font-medium drop-shadow;
    }
    .dropdown-menu-items:hover {
        @apply block absolute z-10 bg-white px-5 py-3 rounded font-['roboto'] text-darkblue font-medium drop-shadow;
    }
    .dropdown-menu-items li {
        @apply my-2;
    }
    .dropdown-menu-items li:hover {
        @apply text-[#489BF6];
    }
    .form-dashboard {
        @apply bg-white rounded-xl block  max-md:p-2 w-full   text-black mt-5 max-md:mt-0;
    }
    .form-dashboard > div > div {
        @apply p-2 max-sm:p-1;
    }
    .form-dashboard input {
        @apply block py-2 rounded-sm border w-full rounded-md px-2 font-['roboto'];
    }
    .form-dashboardtextarea {
        @apply border;
    }
    .form-dashboard label {
        @apply text-darkblue font-['roboto'] font-medium;
    }
    .message-container {
        @apply text-center fixed z-[9999999999] top-5 left-0 w-full max-md:top-2.5;
    }
    .message-container > div {
        @apply m-0 text-left bg-white min-w-message max-w-message inline-block py-5 px-4 rounded-md overflow-hidden shadow-message max-md:py-2.5 max-md:px-1.5 max-md:shadow-message2;
    }
    .message-container > div > div {
        @apply grid grid-cols-[55px_1fr_80px] items-center max-md:grid-cols-[45px_1fr_60px];
    }
    .message-container > div > div > div {
        @apply border-r-[1px] border-[#777777] pr-5 max-md:pr-2.5;
    }
    .message-container img {
        @apply w-10;
    }
    .message-container h4,
    .message-container h5,
    .message-container p {
        @apply m-0;
    }
    .message-container h4 {
        @apply font-medium text-xl mb-1;
    }
    .message-container h5 {
        @apply text-[#777777] text-base;
    }
    .message-container p {
        @apply text-[#ABABAB] text-base cursor-pointer text-center pl-4 max-md:pl-0;
    }
    .message-container p:hover {
        @apply text-[#777777];
    }
    .error-message-box {
        @apply border-l-8 border-[#FE2343] text-[#FE2343];
    }
    .success-message-box {
        @apply border-l-8 border-[#24B056] text-[#24B056];
    }
    .input-number {
        /* Ensure the input is wide enough to accommodate the arrows */
        width: 2.5rem;
        /* Remove the default arrows on Firefox */
        -moz-appearance: textfield;
    }

    /* Remove the spinner arrows on Chrome */
    .input-number::-webkit-inner-spin-button,
    .input-number::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Hide arrows on Firefox */
    .input-number[type="number"] {
        -moz-appearance: textfield;
    }

    /*superadmin metaverse user table css begins*/
    .superadmin-metaverse-user-box {
        @apply shadow-2xl bg-opacity-10 max-md:shadow-none;
    }

    .superadmin-metaverse-user-box > div:nth-child(2) {
        @apply border-t-0;
    }

    .superadmin-metaverse-user-head,
    .superadmin-metaverse-user-body {
        @apply grid gap-1;
    }

    .superadmin-metaverse-user-head {
        @apply p-4 font-semibold text-base max-md:hidden;
    }

    .superadmin-metaverse-user-body {
        @apply p-5 bg-white border-t-[1px] font-['roboto'] text-[14px] border-opacity-75 border-gray-200 max-md:block max-md:border-t-0 max-md:mb-[10px] p-0 rounded-[5px] overflow-hidden;
    }

    .superadmin-metaverse-user-head > p,
    .superadmin-metaverse-user-body > p {
        @apply mb-0 max-md:border-t-[1px] max-md:border-t-[#EEEEEE] px-[10px] py-[15px] max-md:text-left;
    }
    .superadmin-metaverse-user-body > p:first-child {
        @apply max-md:bg-[#c7c7c7] max-md:font-[500]  max-md:px-[8px] max-md:py-[15px];
    }

    .superadmin-metaverse-user-body > p > span {
        @apply hidden font-semibold max-md:inline-block;
    }

    .superadmin-metaverse-user-body p.red {
        @apply text-red-500;
    }

    .superadmin-metaverse-user-body p.green {
        @apply text-green-500;
    }
    /*superadmin metaverse user table css ends*/
    .superadmin-dashboard-user {
        @apply grid grid-cols-[100px_1fr] max-md:block;
    }
    .superadmin-dashboard-user > span {
        @apply font-[600];
    }
    .superadmin-metaverse-details {
        @apply grid grid-cols-[150px_1fr] max-md:block py-1 border-b-[1px];
    }
    .superadmin-metaverse-details > span {
        @apply font-[600];
    }
}
